<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-sm-12"><h1>Account Setting</h1></div>
    </div>
    <div class="row">
      <div class="col-md-3 d-none d-md-block" id="menuWrapper">
        <AccountSettingMenu activeMenu="privacy" />
      </div>
      <div class="col-md-9">
        <SettingTitle title="Privacy" />
        <div class="card">
          <div class="card-header">Social Connections</div>
          <div class="card-body">
            <div class="card-text">
              <p>
                Social Connecions highlights your Trepe activity, which may include your username,
                Facebook or Google profile photo, and recent locations you visited to your Facebook
                or Google friends who are also on Trepr.
              </p>
              <p>
                If you turn off this feature, you will still be connected to Facebook and Google,
                but your Trepr activity will not be shared to other Facebook friends on Trepr. Your
                public Trepr activity (such as short list and public reviews) on the platform will
                still be shown to other Trepr members.
              </p>
              <p>
                If you want to disconnect your Facebook or Google account from Trepr, you may
                uncheck option below.
              </p>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" checked />
                <label class="form-check-label">
                  Share my activity with my Facebook friends that are also on Trepr (recommended)
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-header">Your Listings & Profile in Search Engines</div>
          <div class="card-body">
            <div class="card-text">
              <p>
                Search engines attract lots of traffic to your listing and generate interest and
                requests for our members.
              </p>
              <p>
                Perhaps you want to be listed on Trepr but have concerns about your listings and
                profile being advertised more widely. You can turn off search indexing, preventing
                search engines such as Google and Bing from displaying your pages in their search
                results.
              </p>
              <div class="alert alert-info">
                <p class="text-small">
                  <strong>Note:</strong> This may reduce your service requests and will take a few
                  days to take effect.
                </p>
              </div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" checked />
                <label class="form-check-label">
                  Include my profile and listing in search engines like Google and Bing
                  (recommended)
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-header">Your Personal Details</div>
          <div class="card-body">
            <div class="card-text">
              <p>
                Once my travel service request or pre approval request is approved by the other
                user, then following my personal details can be shown to the user.
              </p>
              <div class="alert alert-info">
                <p class="text-small">
                  <strong>Note:</strong> This may reduce your service requests and will take a few
                  days to take effect.
                </p>
              </div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" checked />
                <label class="form-check-label"> My Phone Number </label>
              </div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" checked />
                <label class="form-check-label"> My Email Address </label>
              </div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" checked />
                <label class="form-check-label"> My Physical Address </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSettingMenu from './Menu';
import SettingTitle from './SettingTitle';
export default {
  name: 'AccountSetting',
  components: { AccountSettingMenu, SettingTitle },
};
</script>

<style scoped></style>
